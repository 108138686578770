import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const UnitedUrologyLandingPage = () => (
  <PhysicianLandingPage
    physician="United Urology"
    institution="United Urology"
    referralCode="UNITEDUROLOGY"
    physicianURL="https://www.unitedurology.com/"
  />
)

export default UnitedUrologyLandingPage
